import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [

  // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  }, 
  {
    path: "",
    title: "Settings",
    moduleName: "setting",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "#",
        title: "Organization Setup",
        moduleName: "setting",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "user/subscriptions",
        title: "Subscription Fee",
        moduleName: "setting",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "user/list",
        title: "User Access",
        moduleName: "setting",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "trading/excelimporter",
    title: "Excel Importer",
    moduleName: "excelimporter",
    iconType: "material-icons-two-tone",
    icon: "file_open",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "trading/dashboard",
    title: "Trading",
    moduleName: "trading",
    iconType: "material-icons-two-tone",
    icon: "psychology",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  {
    path: "",
    title: "Report",
    moduleName: "report",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "reports/margin-report",
        title: "Margin Summary",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "reports/position-report",
        title: "Position",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "reports/holding-report",
        title: "Holding",
        moduleName: "report",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

];
